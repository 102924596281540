import { graphql } from 'gatsby'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { VideoPlayer } from '../../components/common/video-player'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Pět mýtů o novoročních předsevzetích" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Pět mýtů o novoročních předsevzetích" />
        <ContentSubPage>
          <h4>Jsou umělá a proto nefungují</h4>
          <p>
            Na první pohled to tak může vypadat. Zničehonic si říct: od zítřka
            začínám s jógou, třídím odpad a učím se holandsky. Ale ruku na
            srdce, podobným způsobem se rodí i mnohem zásadnější změny v našich
            životech. Moment rozhodnutí stojí za výběrem povolání, za
            manželstvím a většinou i založením rodiny. Za tím, kde bydlet a jak
            trávit volný čas. Tak proč se nepokusit tím správným rozhodnutím
            změnit k lepšímu byť jen malou část našeho každodenního života?
          </p>
          <h4>
            Předsevzetí si můžu dát kdykoliv, není důvod nechat to zrovna na
            Nový rok
          </h4>
          <p>
            Konec roku je symbolický předěl. Navíc po shonu Vánoc, které nás
            vytrhnou z všední rutiny, v dobrém i tom horším, se budeme mnohem
            pravděpodobněji cítit připraveni na změnu. A až se za pár let budete
            chtít ohlédnout a spočítat, jak dlouho už pravidelně cvičíte či kdy
            jste se vlastně pustili do psaní své úspěšné prvotiny, rozhodně se
            vám to bude lépe pamatovat. A jak nás učí Jára Cimrman, o to taky
            hodně jde.
          </p>
          <h4>Stejně nikomu nevydrží</h4>
          <p>
            Ale ano. Mnohem lépe se ovšem daří těm, kteří se snaží do svého
            života něco přidat. Například chtějí začít cvičit nebo chodit dřív
            spát. Hůř jsou na tom předevzetí, která jsou založena na odpírání,
            třeba jídla nebo alkoholu. Taky platí, že pokud si dáváte novoroční
            předsevzetí, nejspíš svůj život tak jako tak postupně měníte k
            lepšímu. Dokonce desetkrát pravděpodobněji než ti, kteří si
            novoroční předsevzetí nedávají.
          </p>
          <h4>Pokud je porušuji, můžu to rovnou vzdát</h4>
          <p>
            Jsme dlouhodobě zaměření pouze na výkon. Až v poslední době se mluví
            o tom, jak důležité je přijmout neúspěchy. Že jsou normální a nutné.
            Že to kvůli nim nemáme vzdávat. Učí nás mimo jiné sebepřijetí a
            realističtějšímu přístupu k životu a k druhým. Takže to nevzdávejte.
            Prostě začněte znova.
          </p>
          <h4>Je dobré dát si jich více, aspoň jedno pak vyjde</h4>
          <p>
            Naopak, prvním tipem k úspěšnému splnění předevzetí je dát si jen
            jedno. Když ho udržíte, to další si nadělte příští rok.
          </p>
          <p>
            A jiné osvědčené rady? Buďte realisty: hodina v tělocvičně denně by
            vám asi do života zasáhla výrazně. Zato čtvrthodinka intenzivního
            cvičení doma, to už je cíl, který udržíte mnohem pravděpodobněji.
            Vytvořte si zvyk. Než se zautomatizuje, trvá to zhruba dva měsíce.
            Někdy se doporučuje zaznamenávat si postup a úspěchy do deníku. Dnes
            se vašimi neocenitelnými pomocníky při budování nového zvyku stanou
            spíš aplikace a organizéry přímo ve vašem telefonu.
          </p>
          <p className="pt-6 font-bold">
            A kterou aplikaci si vybrat? Pokud nevíte nebo se nemůžete
            rozhodnout, pusťte si video tip od Filipa Bartka:
          </p>
          <VideoPlayer url="https://youtu.be/nW3rsIo4xZU" />
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
