import PropTypes from 'prop-types'
import React from 'react'

const ContentSubPage = ({ children, isFull }) => (
  <div className="container">
    <div className={`${isFull ? '' : 'lg:w-2/3'} mx-auto my-12`}>
      {children}
    </div>
  </div>
)

ContentSubPage.defaultProps = {
  children: null,
  isFull: false,
}

ContentSubPage.propTypes = {
  children: PropTypes.any,
  isFull: PropTypes.bool,
}

export { ContentSubPage }
