import PropTypes from 'prop-types'
import React from 'react'

const HeaderTitle = ({ children, title }) => (
  <header>
    <div className="container mt-2">
      <h1 className="text-4xl lg:text-6xl font-bold text-blue text-center leading-tight md:leading-tight lg:leading-tight lg:whitespace-pre-line">
        {title}
      </h1>
      {children}
    </div>
  </header>
)

HeaderTitle.defaultProps = {
  children: null,
}

HeaderTitle.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
}

export { HeaderTitle }
